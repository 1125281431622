import { ErrorHandler } from "@/libs";
import { HeroBlockComponent } from "@/components";

function Error({ statusCode }: { statusCode: any }) {
  if (statusCode === 404) {
    return render404Error();
  }
  return render500Error();
}

const render404Error = () => {
  return (
    <HeroBlockComponent
      type="HeroBlockData"
      link={{
        label: "Take me Home",
        href: "/home",
      }}
      media={{
        alt: "Not Found page image",
        mediaType: "image/jpeg",
        href: "/404-lions.gif",
      }}
      bannerMedia={null}
      headlineMedia={null}
      subtitle="Well, this is embarrassing"
      title="404 error"
      variant="large"
    />
  );
};

const render500Error = () => {
  return (
    <HeroBlockComponent
      type="HeroBlockData"
      link={{
        label: "Take me Home",
        href: "/home",
      }}
      media={{
        alt: "Error page image",
        mediaType: "image/png",
        href: "/the-work-bg.png",
      }}
      bannerMedia={null}
      headlineMedia={null}
      subtitle="Well, this is embarrassing"
      title="Error"
      variant="large"
    />
  );
};

Error.getInitialProps = ({ res, err }: { res: any; err: any }) => {
  ErrorHandler.noticeError(err);

  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
